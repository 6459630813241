import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { lazy } from "@loadable/component";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import emailjs from "emailjs-com";

const App = lazy(() => import("./App"));

emailjs.init("user_NQL22dABatYybWarztmpD");

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading...</div>}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
